// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-fileroom-js": () => import("./../../../src/pages/fileroom.js" /* webpackChunkName: "component---src-pages-fileroom-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-rendering-js": () => import("./../../../src/pages/rendering.js" /* webpackChunkName: "component---src-pages-rendering-js" */),
  "component---src-pages-vr-view-js": () => import("./../../../src/pages/vr-view.js" /* webpackChunkName: "component---src-pages-vr-view-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-view-panorama-js": () => import("./../../../src/templates/viewPanorama.js" /* webpackChunkName: "component---src-templates-view-panorama-js" */),
  "component---src-templates-view-visualization-js": () => import("./../../../src/templates/viewVisualization.js" /* webpackChunkName: "component---src-templates-view-visualization-js" */),
  "component---src-templates-viewportfolio-js": () => import("./../../../src/templates/viewportfolio.js" /* webpackChunkName: "component---src-templates-viewportfolio-js" */)
}

